import { Navigate } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';
interface AuthGuardProps {
  children: JSX.Element;
}


const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const { currentUser } = useAuth();
  return currentUser ? children : <Navigate to="/login" replace/>;
};

export default AuthGuard;