import { createSlice } from '@reduxjs/toolkit';

export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    value: 0,
    isLogin:false,
    showContent:0 // 0 = New Chart; 1 = Answer
  },
  reducers: {
    setIsLogin: (state,action) => {
      state.isLogin = action.payload;
    },
    setShowContent: (state,action) => {
      state.showContent = action.payload
    },
  },
});

export const { setIsLogin,setShowContent } = counterSlice.actions;

export default counterSlice.reducer;