import { useRoutes } from 'react-router-dom';
import routes from './routes/Index';
import { Suspense } from 'react';
import { AuthProvider, useAuth } from './context/AuthContext';

function AppRoutes() {
  return useRoutes(routes);
}
const App: React.FC = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </Suspense>
  )
}
export default App;
