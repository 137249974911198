import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import AuthGuard from './AuthGuard';
const HomePage = lazy(() => import('../pages/home/Index'));
const LoginPage = lazy(() => import('../pages/user/Login'));
const RegisterPage = lazy(() => import('../pages/user/Register'));
const ForgotPage = lazy(() => import('../pages/user/Forgot'));
const routes: RouteObject[] = [
  {
    path: '/login',
    element: <LoginPage />
  },
  {
    path: '/register',
    element: <RegisterPage />
  },{
    path: '/forgot',
    element: <ForgotPage />
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <HomePage />
      </AuthGuard>
    )
  },
  
];

export default routes;